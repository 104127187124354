import XHtmlArea from 'Molecules/XHtmlArea/XHtmlArea';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { baseHeadingStyle } from 'Atoms/Typography/Headings/BaseHeadingStyle';
import Image from 'Atoms/Image/Image';
import { useRef } from 'react';
import IconLink from 'Atoms/Links/IconLink';
import EventPageModel from 'Models/Pages/EventPage/EventPageModel.interface';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';

type PropTypes = {
  content: EventPageModel;
};

function EventBlock({
  content: {
    heading,
    image,
    bodyText,
    introText,
    inEditMode,
    url,
    isFullyBooked,
    startDate,
  },
}: PropTypes) {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxSmall);
  const lazyLoadRef = useRef<HTMLDivElement>(null);
  const {
    eventLabels: { readMore, fullyBooked },
  } = useTranslationData();

  return (
    <Root ref={lazyLoadRef}>
      <CC>
        <Column>
          {image && (
            <ImageArea {...applyEditModeAttr(inEditMode && 'Image')}>
              {isFullyBooked && <Ribbon>{fullyBooked} </Ribbon>}
              <Image
                src={
                  image.src +
                  (isMobile
                    ? '?preset=event-block-mobile'
                    : '?preset=event-block-desktop')
                }
                alt={image.alt}
                externalRef={lazyLoadRef}
              />
            </ImageArea>
          )}

          <TextContainer>
            <TextWrapper>
              <SubHeadingWrapper>{startDate} </SubHeadingWrapper>

              <Heading {...applyEditModeAttr(inEditMode && 'Heading')}>
                {heading}
              </Heading>
              <BodyWrapper>
                <XHtmlArea
                  css={{ lineHeight: '$lh15' }}
                  {...applyEditModeAttr(inEditMode && 'IntroText')}
                  content={introText}
                />
              </BodyWrapper>
              <IconLink href={url} type={'Arrow'}>
                {readMore}
              </IconLink>
            </TextWrapper>
          </TextContainer>
        </Column>
      </CC>
    </Root>
  );
}

export default EventBlock;

const CC = styled('div', {
  w: '100%',
  mx: 'auto',
  py: 0,
});

const Ribbon = styled('div', {
  px: 4,
  py: 2,
  l: -2,
  t: 2,
  backgroundColor: '$primaryLight1',
  color: '$primary4',
  fontFamily: '$fontSecondary500',
  position: 'absolute',
});

const Root = styled('div', {
  h: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  mb: 4,
  '@mediaMinLarge': {
    mb: 20,
  },
});

const SubHeadingWrapper = styled('div', {
  mb: 2,
  fs: 6,
  color: '$primaryLight3',
  lineHeight: '$lh133',
});

const BodyWrapper = styled('div', {
  mb: 8,
});

const Column = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  width: 'auto',
  '@mediaMinLarge': {
    g: 8,
    flexDirection: 'row',
  },
});

const ImageArea = styled('div', {
  width: '100%',
  display: 'flex',
  position: 'relative',
  order: 0,
  flexGrow: 0,
  flexShrink: 0,
  '& img': {
    height: 'inherit',
    width: 'inherit',
    objectFit: 'cover',
    aspectRatio: '16/9',
  },
  height: '238px',
  '@mediaMinMedium': {
    height: '332px',
  },
  '@mediaMinLarge': {
    w: '528px',
  },
});

const TextContainer = styled('div', {
  display: 'flex',
  pt: 5,
  mr: 8,
  w: '100%',
  color: '$textPrimary',
  p: 4,
  pl: 0,
  '@mediaMinLarge': {
    alignItems: 'start',
    p: 0,
  },
});

const TextWrapper = styled('div', {
  wordBreak: 'break-word',
  '@mediaMinLarge': {
    width: '416px',
  },
  width: 'auto',
});

const Heading = styled('h2', {
  ...baseHeadingStyle,
  fs: 12,
  lineHeight: '$lh116',
  fontFamily: '$fontPrimary400',
  mt: 0,
  mb: 4,
});
