import EventOverviewPageModel from 'Models/Pages/EventPage/EventOverviewPageModel.interface';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import EventBlock from 'Organisms/Blocks/EventBlock';
import SmallHeroBlock from 'Organisms/Blocks/SmallHeroBlock';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { styled } from 'stitches.config';

function EventOverviewPage() {
  const { noEventText, events, heroImage, heroHeader } =
    useCurrentPage<EventOverviewPageModel>();

  return (
    <>
      {heroImage && (
        <SmallHeroBlock image={heroImage} header={heroHeader} overlay={false} />
      )}
      <Root>
        <ContentContainer noPadding>
          {events.length === 0 && <p>{noEventText}</p>}
          {events.map((event) => (
            <EventBlock content={event} key={event.pageId + event.heading} />
          ))}
        </ContentContainer>
      </Root>
    </>
  );
}

const Root = styled('div', {
  p: 4,
  mt: 8,
  '@mediaMinLarge': {
    py: 16,
    mt: 4,
  },
});

export default EventOverviewPage;
